import React from 'react';
import { Card, CardContent, Stack, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Iconify from '../iconify.component';
import { formatNumber } from '../../../utils/numbers.util';

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

const AnalyticsWidget = ({
  title,
  icon,
  total,
  color,
  diff,
  trend,
  sx,
  diffFrom,
}) => {
  let trendIcon = null;
  let trendColor = null;
  if (trend !== null) {
    if (trend === 'up') {
      trendIcon = (
        <Iconify
          sx={{ color: (theme) => theme.palette.success.main }}
          icon="material-symbols:arrow-upward-rounded"
        />
      );
      trendColor = 'var(--mui-palette-success-main)';
    } else {
      trendIcon = (
        <Iconify
          sx={{ color: (theme) => theme.palette.error.main }}
          icon="material-symbols:arrow-downward-rounded"
          color="error"
        />
      );
      trendColor = 'var(--mui-palette-error-main)';
    }
  }
  return (
    <Card
      sx={{
        borderLeft: `5px solid ${color}`,
        boxShadow: 3,
        ...sx,
      }}
    >
      <CardContent>
        <Stack spacing={1}>
          <Stack
            direction="row"
            sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }}
            spacing={3}
          >
            <Stack spacing={1}>
              <Typography color="text.primary" variant="subtitle1">
                {title}
              </Typography>

              <Typography variant="h5" color="text.primary">
                {formatNumber(total || 0)}
              </Typography>
            </Stack>

            <StyledIcon
              sx={{
                color: (theme) => theme.palette[color].main,
                backgroundImage: (theme) =>
                  `linear-gradient(135deg, ${alpha(
                    theme.palette[color].dark,
                    0
                  )} 0%, ${alpha(theme.palette[color].dark, 0.24)} 100%)`,
              }}
            >
              <Iconify icon={icon} width={24} height={24} />
            </StyledIcon>
          </Stack>
          {diff !== null ? (
            <Stack sx={{ alignItems: 'center' }} direction="row" spacing={2}>
              <Stack
                sx={{ alignItems: 'center' }}
                direction="row"
                spacing={0.5}
              >
                {trend !== null ? trendIcon : null}
                <Typography color={trendColor} variant="body2">
                  {diff}%
                </Typography>
              </Stack>
              <Typography color="text.primary" font variant="body2">
                {diffFrom}
              </Typography>
            </Stack>
          ) : null}
        </Stack>
      </CardContent>
    </Card>
  );
};

AnalyticsWidget.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  diffFrom: PropTypes.string,
  total: PropTypes.number.isRequired,
  diff: PropTypes.number,
  trend: PropTypes.string,
  sx: PropTypes.object,
};

AnalyticsWidget.defaultProps = {
  color: 'primary',
  sx: {},
  trend: null,
  diff: null,
  diffFrom: 'Since Last Month',
};

export default AnalyticsWidget;
