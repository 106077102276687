import CELL_DATA_TYPES from '../cell-types.config';

// App sessions module configurations
export const LISTING_COLUMNS = [
  {
    id: 'relatedAppNames',
    label: 'Related App Name',
    width: '70%',
    align: 'left',
    dataKey: 'relatedAppNames',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'relatedAppClickedAt',
    label: 'Click Time',
    width: '30%',
    align: 'left',
    dataKey: 'relatedAppClickedAt',
    sortable: false,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export default LISTING_COLUMNS;
