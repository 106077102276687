// Define application wide routes
const ROUTE_PATH = {
  LOGIN: '/login',
  DASHBOARD: '/',
  CONTENT_APPS: '/content/apps',
  APPS_MANAGE_CATEGORY: '/content/apps/:id/manage-categories',
  USERS: '/users',
  REPORTS_APP_SESSIONS: '/reports/app-sessions',
  ADMIN_USERS: '/settings/access',
  LOGOUT: '/logout',
};

export default ROUTE_PATH;
