import axios from 'axios';
import prepareRequestOptions from '../utils/admin-apis.util';

export const getCounters = async (params) => {
  const options = prepareRequestOptions(
    `/reports/counters${params}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getDataList = async (type) => {
  const options = prepareRequestOptions(
    `/admin/reports/app-sessions/list${type}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const viewSessionById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/reports/app-session/${id}/view`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};
export const gameSessionById = async (id, type) => {
  const options = prepareRequestOptions(
    `/admin/reports/app-session/${id}/game-sessions${type}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const relatedAppItemClickedById = async (id, type) => {
  const options = prepareRequestOptions(
    `/admin/reports/app-session/${id}/related-app-clicks${type}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};
