import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const getCurrentTimestamp = () => Math.ceil(Date.now() / 1000);

export const getDate = (days = 0) => {
  const date = new Date();
  if (days) {
    date.setDate(date.getDate() + days);
  }
  return date;
};

export const formatDate = (inputDate, format = 'YYYY-MM-DD') => {
  if (!inputDate) return '';

  return dayjs(inputDate).format(format);
};

export const formatTimestamp = (inputTimestamp, format = 'YYYY-MM-DD') => {
  if (!inputTimestamp) return '';

  return dayjs(inputTimestamp * 1000).format(format);
};

export const formatDateToUtc = (inputDate) => {
  if (!inputDate) return '';

  return dayjs(inputDate).utc().format();
};

export const formatTimestampToUtc = (inputTimestamp) => {
  if (!inputTimestamp) return '';

  return dayjs(inputTimestamp * 1000)
    .utc()
    .format();
};

export const getRelativeTime = (epoch) => {
  const secondsElapsed = Math.floor(Date.now() / 1000) - epoch;
  const formatter = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });

  if (secondsElapsed < 60) return formatter.format(-secondsElapsed, 'second');
  if (secondsElapsed < 3600)
    return formatter.format(-Math.floor(secondsElapsed / 60), 'minute');
  if (secondsElapsed < 86400)
    return formatter.format(-Math.floor(secondsElapsed / 3600), 'hour');
  return formatter.format(-Math.floor(secondsElapsed / 86400), 'day');
};

export const TimeAgo = ({ epoch }) => {
  const timeAgo = getRelativeTime(epoch);
  return timeAgo;
};

export const getCurrentTimezoneOffset = () => {
  const offset = new Date().getTimezoneOffset(); // Offset in minutes
  const hours = Math.floor(Math.abs(offset) / 60);
  const minutes = Math.abs(offset) % 60;
  const sign = offset <= 0 ? '+' : '-';
  return `${sign}${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;
};
