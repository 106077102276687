import axios from 'axios';
import prepareRequestOptions from '../utils/admin-apis.util';

export const getListUser = async (params = '') => {
  const options = prepareRequestOptions(
    `/admin/users/list${params}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const viewUserById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/user/${id}/view`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};
