const validateSelectedJsonFile = (selectedFiles = null) => {
  let errorMsg = '';

  if (errorMsg === '') {
    const allowedExtensions = /(\.json)$/i;
    if (!allowedExtensions.exec(selectedFiles)) {
      errorMsg = 'Invalid file type selected.';
    }
  }

  return errorMsg || null;
};

const validateSelectedImageFile = (selectedFiles = null) => {
  let errorMsg = '';
  let i = 0;

  const files = selectedFiles;

  if (!files || files?.length === 0) {
    errorMsg = 'Please select image file to upload.';
  }
  if (files?.length > 1) {
    errorMsg = 'Single image file selection allowed.';
  }

  if (errorMsg === '') {
    const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
    for (; i < files?.length; i += 1) {
      if (!allowedExtensions.exec(files[i]?.name)) {
        errorMsg = 'Invalid file type selected.';
      }
    }
  }

  return errorMsg || null;
};

export { validateSelectedJsonFile, validateSelectedImageFile };
