import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@mui/material';
import { LISTING_COLUMNS } from '../../config/module-configs/related-app-items.config';
import Loading from '../table-elements/loading.component';
import ListData from '../table-elements/list-data.component';
import { relatedAppItemClickedById } from '../../services/reports.service';
import SnackbarInfo from '../common/snackbar-info.component';
import Iconify from '../common/iconify.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const RelatedAppItems = ({ title, dataId, onCancel, isLive = false }) => {
  const columns = LISTING_COLUMNS;
  const renderColumnFilter = [];
  const actions = [];
  columns.forEach((item) => {
    if (!(isLive && item.id === 'gameEndTime')) {
      renderColumnFilter.push(item);
    }
  });
  const [renderColumns, setRenderColumns] = useState(renderColumnFilter);

  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    error: false,
    loading: true,
    appliedFilters: {
      search: '',
      platforms: [],
      brd: null,
    },
    filtersCounter: 0,
  });

  const [loading, setLoading] = useState(true);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  useEffect(() => {
    const params = [];
    params.push(`page=${options.page + 1}`);
    params.push(`perPage=${options.rowsPerPage}`);
    if (options.sortBy && options.sortOrder) {
      params.push(`sortBy=${options.sortBy}`);
      params.push(`sortOrder=${options.sortOrder}`);
    }
    const paramsQuery = params.length > 0 ? `?${params.join('&')}` : '';
    relatedAppItemClickedById(dataId, paramsQuery)
      .then((res) => {
        setOptions({
          ...options,
          loading: false,
          totalRows: res?.data?.totalRows || 0,
          rows: res?.data?.rows || [],
          error: false,
        });
        if (res?.data?.totalRows > 0) {
          const columnFilter = [];
          const firstItem = res?.data?.rows[0];
          renderColumns.forEach((item) => {
            if (firstItem[item.dataKey] !== null) {
              columnFilter.push(item);
            }
          });
          setRenderColumns(columnFilter);
          // const setRenderColumns(LISTING_COLUMNS);
        }
        setLoading(false);
      })
      .catch((err) => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        });
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId, options.reloadCounter]);

  return (
    <Dialog
      open
      onClose={() => onCancel()}
      aria-labelledby="view-dialog-title"
      aria-describedby="view-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="view-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="view-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <ListData
            columns={renderColumns}
            rows={options.rows}
            page={options.page}
            rowsPerPage={options.rowsPerPage}
            totalRows={options.totalRows}
            loading={options.loading}
            actions={actions}
            error={options.error}
            sortBy={options.sortBy}
            sortOrder={options.sortOrder}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            onSortChange={handleSortingChange}
          />
        )}
      </DialogContent>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

RelatedAppItems.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  isLive: PropTypes.bool.isRequired,
};

export default RelatedAppItems;
