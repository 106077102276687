import React, { useState, useEffect } from 'react';
import { Grid, Button, Drawer, Typography, Box } from '@mui/material';
// import CountWidget from './count-widget.component';
import { getCounters } from '../../../services/reports.service';
import Iconify from '../iconify.component';
import FilterReports from './filter-reports.component';
import {
  formatDate,
  formatDateToUtc,
  getCurrentTimezoneOffset,
} from '../../../utils/datetime.util';
import AnalyticsWidget from './analytics-widget.component';
import ChartWidget from './charts-widget.component';
import ListData from '../../table-elements/list-data.component';
import { LISTING_COLUMNS } from '../../../config/module-configs/country.config';

const Counters = () => {
  const [openFilters, setOpenFilters] = useState(false);
  const [refreshState, setRefreshState] = React.useState(1);
  const [counters, setCounters] = useState({
    channels: 0,
    videos: 0,
    playlist: 0,
    liveUsers: 0,

    totalInstall: 0,
    todaysInstall: 0,
    yesterdayInstall: 0,
    weeklyInstall: 0,
    lastweekInstall: 0,

    totalBrdUsers: 0,
    todaysBrdUsers: 0,
    yesterdayBrdUsers: 0,
    weeklyBrdUsers: 0,
    lastweekBrdUsers: 0,

    totalAppSessions: 0,
    todayAppSessions: 0,
    yesterdayAppSessions: 0,
    weeklyAppSessions: 0,
    lastweekAppSessions: 0,

    totalGameSessions: 0,
    todayGameSessions: 0,
    yesterdayGameSessions: 0,
    weeklyGameSessions: 0,
    lastweekGameSessions: 0,

    monthlyNames: [],
    monthlyBrdUser: [],
    monthlyInstallUser: [],
  });
  const [countryCount, setCountryCount] = useState({
    countryWiseUser: [],
    reloadCountryCounter: 0,
    loading: true,
    page: 0,
    rows: [],
    rowsPerPage: 10,
    totalRows: 0,
    sortBy: '',
    sortOrder: '',
  });
  const [options, setOptions] = useState({
    reloadCounter: 0,
    loading: true,
    appliedFilters: {
      startDate: null,
      endDate: null,
      filterApp: null,
    },
    filtersCounter: 0,
  });

  const columns = LISTING_COLUMNS;

  const handlePageChange = (v) => {
    setCountryCount({
      ...countryCount,
      page: v,
      reloadCountryCounter: countryCount.reloadCountryCounter + 1,
      loading: true,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setCountryCount({
      ...countryCount,
      rowsPerPage: v,
      page: 0,
      reloadCountryCounter: countryCount.reloadCountryCounter + 1,
      loading: true,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    setCountryCount({
      ...countryCount,
      sortBy,
      sortOrder,
      reloadCountryCounter: countryCount.reloadCountryCounter + 1,
      loading: true,
    });
  };

  useEffect(() => {
    const { countryWiseUser, rowsPerPage, page, sortBy, sortOrder } =
      countryCount;
    const sortedCountryWiseUser = [...countryWiseUser].sort((a, b) => {
      if (sortBy !== '' && sortOrder !== '') {
        if (sortOrder === 'asc') {
          return a[sortBy] > b[sortBy] ? 1 : -1;
        }
        return a[sortBy] < b[sortBy] ? 1 : -1;
      }
      return 1;
    });

    const rowUser = sortedCountryWiseUser.slice(
      page * rowsPerPage,
      (page + 1) * rowsPerPage
    );
    setCountryCount({
      ...countryCount,
      loading: false,
      rows: rowUser || [],
      error: false,
    });
  }, [countryCount.reloadCountryCounter]);

  const getMonthlyName = (res) => {
    const monthlyName = [];
    let count = 1;

    res?.data?.monthlyData?.forEach((element) => {
      monthlyName.push(element?.month || `Month ${count.toString()}`);
      count += 1;
    });
    return monthlyName;
  };

  const getMonthlyBrdEnable = (res) => {
    const monthlyBrd = [];
    res?.data?.monthlyData?.forEach((element) => {
      monthlyBrd.push(element?.totalBrd || 0);
    });
    return monthlyBrd;
  };
  const getMonthlyTotal = (res) => {
    const monthlyTotal = [];
    res?.data?.monthlyData?.forEach((element) => {
      monthlyTotal.push(element?.totalInstall || 0);
    });
    return monthlyTotal;
  };
  useEffect(() => {
    const params = [];
    const filters = options.appliedFilters;
    params.push(`timezone=${encodeURIComponent(getCurrentTimezoneOffset())}`);
    if (filters.startDate) {
      const startDate = formatDateToUtc(filters.startDate);
      params.push(`startDate=${encodeURIComponent(startDate)}`);
    }
    if (filters.endDate) {
      const endDate = formatDateToUtc(filters.endDate);
      params.push(`endDate=${encodeURIComponent(endDate)}`);
    }
    if (filters.filterApp && filters.filterApp.id) {
      params.push(`appId=${encodeURIComponent(filters.filterApp.id)}`);
    }
    const paramsQuery = params.length > 0 ? `?${params.join('&')}` : '';
    getCounters(paramsQuery)
      .then((res) => {
        setCountryCount({
          ...countryCount,
          countryWiseUser: res?.data?.countryWiseUser || [],
          loading: false,
          totalRows: res?.data?.countryWiseUser?.length || 0,
          rows: res?.data?.countryWiseUser?.slice(0, 10) || [],
          error: false,
        });
        setCounters({
          ...counters,
          channels: res?.data?.channels || 0,
          apps: res?.data?.apps || 0,
          playlist: res?.data?.playlist || 0,
          liveUsers: res?.data?.liveUsers || 0,

          totalInstall: res?.data?.installs?.total || 0,
          todaysInstall: res?.data?.installs?.today || 0,
          yesterdayInstall: res?.data?.installs?.yesterday || 0,
          weeklyInstall: res?.data?.installs?.weekly || 0,
          lastweekInstall: res?.data?.installs?.lastWeek || 0,

          totalBrdUsers: res?.data?.brdUsers?.total || 0,
          todaysBrdUsers: res?.data?.brdUsers?.today || 0,
          yesterdayBrdUsers: res?.data?.brdUsers?.yesterday || 0,
          weeklyBrdUsers: res?.data?.brdUsers?.weekly || 0,
          lastweekBrdUsers: res?.data?.brdUsers?.lastWeek || 0,

          totalAppSessions: res?.data?.appSessions?.total || 0,
          todayAppSessions: res?.data?.appSessions?.today || 0,
          yesterdayAppSessions: res?.data?.appSessions?.yesterday || 0,
          weeklyAppSessions: res?.data?.appSessions?.weekly || 0,
          lastweekAppSessions: res?.data?.appSessions?.lastWeek || 0,

          totalGameSessions: res?.data?.gameSessions?.total || 0,
          todayGameSessions: res?.data?.gameSessions?.today || 0,
          yesterdayGameSessions: res?.data?.gameSessions?.yesterday || 0,
          weeklyGameSessions: res?.data?.gameSessions?.weekly || 0,
          lastweekGameSessions: res?.data?.gameSessions?.lastWeek || 0,

          monthlyNames: getMonthlyName(res),
          monthlyBrdUser: getMonthlyBrdEnable(res),
          monthlyInstallUser: getMonthlyTotal(res),
        });
      })
      .catch(() => {
        // ignore
        setCountryCount({
          ...countryCount,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        });
      });
  }, [options.reloadCounter, refreshState]);

  const handleToggleFilters = () => {
    setOpenFilters(!openFilters);
  };

  const handleRefresh = () => {
    setRefreshState(refreshState + 1);
  };

  const handleApplyFilters = (selectedFilters) => {
    let counter = 0;
    if (selectedFilters.startDate) counter += 1;
    if (selectedFilters.endDate) counter += 1;
    if (selectedFilters.filterApp) counter += 1;

    setOptions({
      ...options,
      appliedFilters: {
        ...selectedFilters,
      },
      filtersCounter: counter,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
    handleToggleFilters();
  };
  const handleClearFilters = () => {
    handleToggleFilters();
    setOptions({
      ...options,
      appliedFilters: {
        startDate: null,
        endDate: null,
        filterApp: null,
      },
      filtersCounter: 0,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  return (
    <div>
      <Grid container>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          paddingY="16px"
          width="100%"
        >
          <Grid container xs gap={1} alignItems="center">
            <Typography color="text.primary" font variant="h6">
              Apps -
            </Typography>
            <span>{options.appliedFilters?.filterApp?.title || 'All'}</span>
          </Grid>
          <Grid container xs justifyContent="flex-end">
            <Button
              color="inherit"
              variant="contained"
              startIcon={<Iconify icon="mdi:refresh" />}
              sx={{ marginRight: 2, justifyContent: 'flex-end' }}
              onClick={handleRefresh}
            >
              Refresh
            </Button>
            <Button
              color={options.filtersCounter > 0 ? 'warning' : 'inherit'}
              variant="contained"
              startIcon={<Iconify icon="ic:round-filter-list" />}
              sx={{ marginRight: 2, justifyContent: 'flex-end' }}
              onClick={handleToggleFilters}
            >
              Filters
              {options.filtersCounter > 0 ? ` (${options.filtersCounter})` : ''}
            </Button>
          </Grid>
        </Box>
        <Grid container columnSpacing={3} rowSpacing={3} mb={1}>
          <Grid item xs={12} sm={3} md={3}>
            <AnalyticsWidget
              title="Apps"
              total={counters.apps}
              color="info"
              icon="octicon:video-16"
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <AnalyticsWidget
              title="Live Users"
              total={counters.liveUsers}
              color="info"
              icon="fa6-solid:users"
            />
          </Grid>
        </Grid>
        <Grid container xs gap={1} alignItems="center" paddingY="16px">
          <Typography color="text.primary" font variant="h6">
            Total
          </Typography>
          <Box display="flex">
            <Box fontWeight="bold">{'(Date Range - '}</Box>
            <span>
              &nbsp;
              {(options.appliedFilters?.startDate ||
                options.appliedFilters?.endDate) && (
                <>
                  {'From '}
                  {options.appliedFilters?.startDate
                    ? formatDate(options.appliedFilters.startDate, 'YYYY-MM-DD')
                    : 'starts'}
                  {' To '}
                  {options.appliedFilters?.endDate
                    ? formatDate(options.appliedFilters.endDate, 'YYYY-MM-DD')
                    : 'today'}
                </>
              )}
              {!options.appliedFilters?.startDate &&
                !options.appliedFilters?.endDate &&
                'All'}
            </span>
            <Box fontWeight="bold">{' )'}</Box>
          </Box>
        </Grid>
        <Grid container columnSpacing={3} rowSpacing={3} mb={3}>
          <Grid item xs={12} sm={3} md={3}>
            <AnalyticsWidget
              title="Installs"
              total={counters.totalInstall}
              icon="material-symbols:install-desktop-rounded"
              color="info"
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <AnalyticsWidget
              title="BRD Users"
              total={counters.totalBrdUsers}
              color="info"
              icon="fa6-solid:users"
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <AnalyticsWidget
              title="App Sessions"
              total={counters.totalAppSessions}
              color="info"
              icon="mdi:account-box-multiple"
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <AnalyticsWidget
              title="Game Sessions"
              total={counters.totalGameSessions}
              color="info"
              icon="mdi:laptop-account"
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={3} mb={3}>
          <Grid item xs={12} sm={6} md={6}>
            <ChartWidget
              title="Weekly"
              xaxisOption={[
                'Installs',
                'Brd Enable',
                'App Sessions',
                'Game Sessions',
              ]}
              chartSeries={[
                {
                  name: 'Last week',
                  data: [
                    counters.lastweekInstall,
                    counters.lastweekBrdUsers,
                    counters.lastweekAppSessions,
                    counters.lastweekGameSessions,
                  ],
                },
                {
                  name: 'This week',
                  data: [
                    counters.weeklyInstall,
                    counters.weeklyBrdUsers,
                    counters.weeklyAppSessions,
                    counters.weeklyGameSessions,
                  ],
                },
              ]}
              sx={{ height: '100%' }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <ChartWidget
              title="Daily"
              xaxisOption={[
                'Installs',
                'Brd Enable',
                'App Sessions',
                'Game Sessions',
              ]}
              chartSeries={[
                {
                  name: 'Yesterday',
                  data: [
                    counters.yesterdayInstall,
                    counters.yesterdayBrdUsers,
                    counters.yesterdayAppSessions,
                    counters.yesterdayGameSessions,
                  ],
                },
                {
                  name: 'Today',
                  data: [
                    counters.todaysInstall,
                    counters.todaysBrdUsers,
                    counters.todayAppSessions,
                    counters.todayGameSessions,
                  ],
                },
              ]}
              sx={{ height: '100%' }}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={3} mb={3}>
          <Grid item xs={12} sm={6} md={6}>
            <ChartWidget
              title="Monthly"
              xaxisOption={counters.monthlyNames}
              chartSeries={[
                {
                  name: 'Install',
                  data: counters.monthlyInstallUser,
                },
                {
                  name: 'Brd Enable',
                  data: counters.monthlyBrdUser,
                },
              ]}
              sx={{ height: '100%' }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <ListData
              columns={columns}
              rows={countryCount.rows}
              page={countryCount.page}
              rowsPerPage={countryCount.rowsPerPage}
              totalRows={countryCount.totalRows}
              loading={countryCount.loading}
              actions={[]}
              error={countryCount.error}
              sortBy={countryCount.sortBy}
              sortOrder={countryCount.sortOrder}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              onSortChange={handleSortingChange}
            />
          </Grid>
        </Grid>

        <Drawer
          anchor="right"
          open={openFilters}
          onClose={handleToggleFilters}
          PaperProps={{
            sx: { width: 300, border: 'none', overflow: 'hidden' },
          }}
        >
          <FilterReports
            values={options.appliedFilters}
            onCancel={handleToggleFilters}
            onSuccess={handleApplyFilters}
            onClear={handleClearFilters}
          />
        </Drawer>
      </Grid>
    </div>
  );
};

export default Counters;
