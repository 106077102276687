import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Tabs, Tab, Box } from '@mui/material';
import ListCategories from '../../components/content/list-categories.component';
import ListImages from '../../components/content/list-images.component';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CategoriesTab = () => {
  const { id } = useParams();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="tabs">
          <Tab label="Categories" {...a11yProps(0)} />
          <Tab label="Images" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <Box>
        <TabPanel value={value} index={0}>
          <ListCategories appId={id || 0} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ListImages appId={id || 0} />
        </TabPanel>
      </Box>
    </Box>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
};
export default CategoriesTab;
