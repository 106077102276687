// Define commonly used constants
export const LS_ADMIN_USER_AUTH_TOKEN = '__happ_admin_t';
export const LS_ADMIN_USER_NAME = '__happ_admin_n';
export const LS_ADMIN_USER_EMAIL = '__happ_admin_e';
export const LS_ADMIN_USER_ROLE = '__happ_admin_r';

export const VALID_ADMIN_ROLES = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
};

export const STATUS_LABELS = {
  0: 'Inactive',
  1: 'Active',
  2: 'Deleted',
};

export const STATUS_COLORS = {
  0: 'error',
  1: 'success',
  2: 'error',
};

export const IS_DEFAULT = {
  0: 'No',
  1: 'Yes',
};

export const STATUS_ALL_VALUE = 99999;

export const ACTIONS = {
  CREATE: {
    value: 'create',
    label: 'Create New',
  },
  COPY: {
    value: 'copy',
    label: '',
  },
  EDIT: {
    value: 'update',
    label: 'Edit',
  },
  VIEW: {
    value: 'view',
    label: 'View',
  },
  GAME_SESSION: {
    value: 'gameSessions',
    label: 'GameSession',
  },
  RELATED_APP_ITEM_CLICKED: {
    value: 'relatedAppItemClicked',
    label: 'RelatedAppItemClicked',
  },
  DELETE: {
    value: 'delete',
    label: 'Delete',
  },
  CHANGE_PASSWORD: {
    value: 'change_password',
    label: 'Change Password',
  },
  CONFIG: {
    value: 'config',
    label: 'Config',
  },
  MANAGE_CATEGORY: {
    value: 'manage_categories',
    label: 'Manage Categories',
  },
  MANAGE_IMAGE: {
    value: 'manage_images',
    label: 'Manage Images',
  },
};

export const APPS_TYPE_LIST = [
  {
    id: 'puzzle',
    title: 'Puzzle',
  },
  {
    id: 'card',
    title: 'Card',
  },
  {
    id: 'blockpuzzle',
    title: 'Block Puzzle',
  },
  {
    id: 'trivia',
    title: 'Trivia',
  },
];

export const CONTENT_ALIAS = {
  VIDEO_SINGULAR: 'Video',
};

export const BRD_VALUES = {
  0: 'Off',
  1: 'On',
};
export const BRD_VALUES_LIST = [
  {
    id: 0,
    title: 'Off',
  },
  {
    id: 1,
    title: 'On',
  },
];

export const getEnumLabel = (enumValues, val) => {
  let label = '';
  if (enumValues) {
    enumValues.forEach((p) => {
      if (label === '' && p.id === val) {
        label = p.title;
      }
    });
  }
  return label;
};
