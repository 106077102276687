import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  Stack,
  Button,
  Divider,
  Typography,
  TextField,
} from '@mui/material';
import Iconify from '../common/iconify.component';
import Scrollbar from '../common/scrollbar.component';
import Platforms from '../form-elements/platforms.component';
import Channels from '../form-elements/channels.component';
import AppType from '../form-elements/app-type.component';

const FilterContent = ({
  values,
  onCancel,
  onSuccess,
  onClear,
  hideSerachFilter,
}) => {
  const [filters, setFilters] = useState(values);

  const handleApplyFilters = () => {
    setTimeout(() => {
      onSuccess(filters);
    }, 250);
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ px: 1, py: 2 }}
      >
        <Typography variant="subtitle1" sx={{ ml: 1 }}>
          Filters
        </Typography>
        <IconButton onClick={onCancel}>
          <Iconify icon="eva:close-fill" />
        </IconButton>
      </Stack>

      <Divider />

      <Scrollbar
        sx={{
          height: 1,
          '& .simplebar-content': {
            height: 1,
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <form id="list-data-filters" onSubmit={(e) => e.preventDefault()}>
          <Stack sx={{ p: 2 }}>
            {!hideSerachFilter ? (
              <TextField
                id="fl_search"
                name="fl_search"
                label="Search"
                variant="filled"
                defaultValue={filters.search}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    search: e.target.value,
                  });
                }}
                sx={{ width: '100%', mb: 1 }}
              />
            ) : (
              <>
                <Channels
                  appId="filterApps"
                  appName="filterApps"
                  label="Select App"
                  defaultValue={filters.filterApp || null}
                  onChange={(v) => {
                    setFilters({
                      ...filters,
                      filterApp: v,
                    });
                  }}
                  sx={{ width: '100%' }}
                />
                <Platforms
                  id="filterPlatforms"
                  name="filterPlatforms"
                  label="Select Platforms"
                  defaultValue={filters.filterPlatform || null}
                  multiple
                  onChange={(v) => {
                    setFilters({
                      ...filters,
                      filterPlatform: v,
                    });
                  }}
                  sx={{ width: '100%', my: 1 }}
                />
                <AppType
                  id="app_type"
                  name="app_type"
                  label="Type"
                  defaultValue={filters.filterAppType || null}
                  onChange={(v) => {
                    setFilters({
                      ...filters,
                      filterAppType: v,
                    });
                  }}
                  sx={{ width: '100%', mt: 1 }}
                />
              </>
            )}
          </Stack>
        </form>
      </Scrollbar>

      <Divider />

      <Stack spacing={1} sx={{ p: 2 }}>
        <Button
          fullWidth
          form="list-data-filters"
          type="submit"
          color="primary"
          variant="contained"
          onClick={handleApplyFilters}
        >
          Apply Filters
        </Button>
        <Button
          fullWidth
          type="button"
          color="secondary"
          variant="contained"
          startIcon={<Iconify icon="ic:round-clear-all" />}
          onClick={onClear}
        >
          Clear All
        </Button>
      </Stack>
    </>
  );
};

FilterContent.propTypes = {
  values: PropTypes.shape().isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  hideSerachFilter: PropTypes.bool,
};
FilterContent.defaultProps = {
  hideSerachFilter: false,
};

export default FilterContent;
