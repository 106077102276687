import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, DialogActions, Grid } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import Dropzone from 'react-dropzone';
import { validateSelectedJsonFile } from '../../utils/file-validations.util';

const ButtonConfigs = ({
  handleUpdateBtn,
  handleChangeFile,
  row,
  viewData,
  dataId,
  setSnackbarInfo,
  snackbarInfo,
}) => {
  const [selectedFileName, setSelectedFileName] = useState('');
  const [selectedFilesObj, setSelectedFilesObj] = useState(null);

  // eslint-disable-next-line no-promise-executor-return
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleUploadFiles = async (fileName) => {
    setSnackbarInfo({ ...snackbarInfo, show: false });
    const validationError = validateSelectedJsonFile(fileName);
    const message = 'Please upload JSON again.';
    if (validationError) {
      setTimeout(
        () =>
          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'error',
            message,
          }),
        50
      );
    }

    if (selectedFilesObj) {
      const payload = {
        file: selectedFilesObj,
        fileName,
      };
      try {
        handleChangeFile(payload);
      } catch (err) {
        // Skipped
      }
    }
  };

  const handleSelectFiles = async (files) => {
    setSnackbarInfo({ ...snackbarInfo, show: false });
    // Show file name below button
    if (files?.length > 0) {
      if (`${row?.name?.replace(/ /g, '_')}_config.json` === files[0].name) {
        setSelectedFilesObj(files[0]);
        await sleep(100);
        setSelectedFileName(files[0].name);
      } else {
        setTimeout(
          () =>
            setSnackbarInfo({
              ...snackbarInfo,
              show: true,
              type: 'error',
              message: `Please upload JSON file with correct name. File name should be ${row?.name?.replace(/ /g, '_')}_config.json`,
            }),
          50
        );
      }
    }
  };

  return (
    <Grid2>
      <Button
        color="secondary"
        variant="contained"
        sx={{ marginRight: 5 }}
        onClick={() => handleUpdateBtn()}
      >
        Set Default
      </Button>
      <Button
        color="secondary"
        variant="contained"
        sx={{ marginRight: 5 }}
        onClick={() => viewData(dataId, true)}
      >
        Download
      </Button>

      <Dropzone onDrop={(acceptedFiles) => handleSelectFiles(acceptedFiles)}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} />

              <Box
                component="section"
                sx={{
                  p: 3,
                  border: '1px dashed grey',
                  borderRadius: '20px',
                  width: '100%',
                  marginTop: 3,
                }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      fontWeight: 'bold',
                    }}
                  >
                    Add JSON file
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      sx={{ display: 'flex', justifyContent: 'center' }}
                    >
                      Drag and drop json files here, or click to browse
                    </Grid>

                    <DialogActions sx={{ justifyContent: 'center' }}>
                      <Button
                        component="label"
                        variant="contained"
                        // disabled={disableBtn}
                        sx={{
                          backgroundColor: '#808080',
                        }}
                      >
                        Select json File
                      </Button>
                    </DialogActions>
                  </Grid>
                </Grid>

                <Grid
                  item
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {`${selectedFileName}`}
                </Grid>
              </Box>
            </div>
          </section>
        )}
      </Dropzone>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button
          color="primary"
          variant="contained"
          // disabled={disableBtn}
          onClick={() => handleUploadFiles(selectedFileName)}
        >
          Upload JSON File
        </Button>
      </DialogActions>
    </Grid2>
  );
};

ButtonConfigs.propTypes = {
  handleUpdateBtn: PropTypes.func.isRequired,
  handleChangeFile: PropTypes.func.isRequired,
  row: PropTypes.any.isRequired,
  viewData: PropTypes.func.isRequired,
  dataId: PropTypes.string.isRequired,
  setSnackbarInfo: PropTypes.func.isRequired,
  snackbarInfo: PropTypes.object.isRequired,
};

export default ButtonConfigs;
