// Define cell data type
const CELL_DATA_TYPES = {
  TEXT: 'text',
  TIMEAGO: 'timeago',
  ENUM: 'enum',
  EMAIL: 'email',
  NUMBER: 'number',
  DATE: 'date',
  DATETIME: 'datetime',
  TIMESTAMP: 'timestamp',
  DURATION: 'duration',
  STATUS: 'status',
  SWITCH: 'switch',
  PLATFORM: 'platform',
  MONEY: 'money',
  PERCENTAGE: 'percent',
  BOOL: 'boolean',
  IMAGE: 'image',
  CODE: 'code',
  ADMIN_ROLE: 'role',
  PASSWORD: 'password',
};

export default CELL_DATA_TYPES;
