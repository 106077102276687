import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';
import { getAppsList } from '../../services/apps.service';

const Channels = ({
  appId,
  appName,
  label,
  defaultValue,
  multiple,
  disabled,
  onChange,
  sx,
  error,
}) => {
  const selectedValue = defaultValue || (multiple ? [] : null);

  const [value, setValue] = useState(selectedValue);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    getAppsList().then((res) => {
      const optionsData = [];
      // channelIds
      const channelsData = res?.data?.rows || [];
      channelsData.map(({ name, id: channelIndexId }) =>
        optionsData.push({
          id: channelIndexId,

          title: `${name}`,
        })
      );
      setOptions(optionsData);
    });
  }, []);

  return (
    <Autocomplete
      disablePortal
      id={appId}
      name={appName}
      options={options}
      getOptionLabel={(option) => option.title}
      sx={sx}
      value={value}
      onChange={(e, v) => {
        setValue(v);
        onChange(v);
      }}
      inputValue={inputValue}
      onInputChange={(e, v) => {
        setInputValue(v);
      }}
      multiple={multiple}
      disabled={disabled}
      isOptionEqualToValue={(op, vl) => op.id === vl.id}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={error !== ''}
          helperText={error}
          variant="filled"
        />
      )}
    />
  );
};

Channels.propTypes = {
  appId: PropTypes.string.isRequired,
  appName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  sx: PropTypes.shape(),
  error: PropTypes.string,
};
Channels.defaultProps = {
  multiple: false,
  disabled: false,
  sx: {},
  defaultValue: null,
  error: '',
};

export default Channels;
