import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

// Users module configurations
export const LISTING_COLUMNS = [
  {
    id: 'appName',
    label: 'App Name',
    width: '10%',
    align: 'left',
    dataKey: 'appName',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'platform',
    label: 'Platfrom',
    width: '10%',
    align: 'left',
    dataKey: 'platform',
    sortable: true,
    type: CELL_DATA_TYPES.PLATFORM,
  },
  {
    id: 'appVersion',
    label: 'App Version',
    width: '5%',
    align: 'center',
    dataKey: 'appVersion',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'isLive',
    label: 'Live',
    width: '5%',
    align: 'center',
    dataKey: 'isLive',
    sortable: true,
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'brdValue',
    label: 'BRD',
    width: '5%',
    align: 'center',
    dataKey: 'brdValue',
    sortable: true,
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'totalSessions',
    label: 'Sessions',
    width: '5%',
    align: 'center',
    dataKey: 'totalSessions',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'deviceId',
    label: 'Device Id',
    width: '15%',
    align: 'left',
    dataKey: 'deviceId',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'countryName',
    label: 'Country / Region',
    width: '10%',
    align: 'left',
    dataKey: 'countryName',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
    secondaryFields: [
      {
        dataKey: 'regionName',
        type: CELL_DATA_TYPES.TEXT,
      },
    ],
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    width: '15%',
    align: 'left',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'lastSessionCreatedAt',
    label: 'Last Session Created Date',
    width: '15%',
    align: 'left',
    dataKey: 'lastSessionCreatedAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
];

export const VIEW_COLUMNS = [
  {
    id: 'appName',
    label: 'App Name',
    dataKey: 'appName',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'platform',
    label: 'Platform',
    dataKey: 'platform',
    type: CELL_DATA_TYPES.PLATFORM,
  },
  {
    id: 'appVersion',
    label: 'App Version',
    dataKey: 'appVersion',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'brdValue',
    label: 'BRD',
    dataKey: 'brdValue',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'totalSessions',
    label: 'Sessions',
    dataKey: 'totalSessions',
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'deviceId',
    label: 'Device Id',
    dataKey: 'deviceId',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'ip',
    label: 'IP Address',
    dataKey: 'ip',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'countryName',
    label: 'Country / Region',
    dataKey: 'countryName',
    type: CELL_DATA_TYPES.TEXT,
    secondaryFields: [
      {
        dataKey: 'regionName',
        type: CELL_DATA_TYPES.TEXT,
      },
    ],
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'lastSessionCreatedAt',
    label: 'Last SessionCreated Date',
    dataKey: 'lastSessionCreatedAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];
