import CELL_DATA_TYPES from '../cell-types.config';

// Apps module configurations
export const LISTING_COLUMNS = [
  {
    id: 'countryName',
    label: 'Country Name',
    width: '70%',
    align: 'left',
    dataKey: 'countryName',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'count',
    label: 'Type',
    width: '30%',
    align: 'left',
    dataKey: 'count',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
];

export const LISTING_ACTIONS = [];

export const VIEW_COLUMNS = [
  {
    id: 'countryName',
    label: 'Country Name',
    dataKey: 'countryName',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'count',
    label: 'Count',
    dataKey: 'count',
    type: CELL_DATA_TYPES.TEXT,
  },
];
