import React from 'react';
import { Typography, Chip, Box, Switch, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import CELL_DATA_TYPES from '../../config/cell-types.config';
import { getPlatformLabel } from '../../config/platforms.config';
import { getAdminRoleLabel } from '../../config/admin-roles.config';
import {
  formatNumber,
  formatPercent,
  formatDuration,
  formatCurrency,
} from '../../utils/numbers.util';
import {
  formatDate,
  formatTimestamp,
  TimeAgo,
} from '../../utils/datetime.util';
import StatusLabel from './status-label.component';
import PasswordLabel from './password-label.component';
import { getEnumLabel } from '../../config/const.config';
import Iconify from '../common/iconify.component';

const CellValue = ({
  type,
  value,
  color,
  icon,
  action,
  rowData,
  onIconClick,
  enumValues,
  fallbackValue,
  disabledToggle,
}) => {
  if (type === CELL_DATA_TYPES.NUMBER) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {formatNumber(value || 0)}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.PERCENTAGE) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {formatPercent(value || 0)}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.MONEY) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {formatCurrency(value || 0)}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.DURATION) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {formatDuration(value || 0)}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.DATE) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {formatDate(value || null, 'll')}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.DATETIME) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {formatDate(value || null, 'lll')}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.TIMESTAMP) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {formatTimestamp(value || null, 'MMM D, YYYY h:mm A')}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.BOOL) {
    return (
      <Chip
        label={Number(value) === 1 ? 'Yes' : 'No'}
        color={Number(value) === 1 ? 'success' : 'error'}
        variant="filled"
        size="small"
        sx={{ fontWeight: 700 }}
      />
    );
  }

  if (type === CELL_DATA_TYPES.ICON) {
    return (
      <IconButton
        aria-label={action}
        onClick={() => onIconClick(action, rowData)}
      >
        <Iconify icon={icon} />
      </IconButton>
    );
  }
  if (type === CELL_DATA_TYPES.PLATFORM) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {getPlatformLabel(value || '')}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.ENUM) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {getEnumLabel(enumValues, value || '') || value}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.SWITCH) {
    return (
      <Switch
        aria-label="status"
        color="primary"
        defaultChecked={!!value}
        onChange={(e) => fallbackValue(e)}
        disabled={disabledToggle}
      />
    );
  }
  if (type === CELL_DATA_TYPES.STATUS) {
    return <StatusLabel value={value} />;
  }
  if (type === CELL_DATA_TYPES.TIMEAGO) {
    return <TimeAgo epoch={value} />;
  }
  if (type === CELL_DATA_TYPES.PASSWORD) {
    return <PasswordLabel value={value} />;
  }
  if (type === CELL_DATA_TYPES.CODE) {
    return (
      <Box
        component="span"
        sx={{
          whiteSpace: 'unset',
          wordBreak: 'break-all',
        }}
        color={color}
      >
        {value || ''}
      </Box>
    );
  }
  if (type === CELL_DATA_TYPES.IMAGE) {
    if (value === null || value === '') {
      return (
        <Box
          sx={{
            height: 54,
            width: 96,
            bgcolor: '#808080',
          }}
        />
      );
    }

    return (
      <Box
        component="img"
        sx={{
          height: 54,
          width: 96,
          objectFit: 'contain',
        }}
        src={value}
      />
    );
  }
  if (type === CELL_DATA_TYPES.ADMIN_ROLE) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {getAdminRoleLabel(value || '')}
      </Typography>
    );
  }

  return (
    <Typography variant="body2" component="span" color={color}>
      {value || ''}
    </Typography>
  );
};

CellValue.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.any,
  enumValues: PropTypes.any,
  color: PropTypes.string,
  icon: PropTypes.any,
  action: PropTypes.string,
  rowData: PropTypes.any,
  onIconClick: PropTypes.func,
  fallbackValue: PropTypes.func,
  disabledToggle: PropTypes.bool,
};
CellValue.defaultProps = {
  value: null,
  color: 'inherit',
  enumValues: null,
  icon: null,
  action: null,
  rowData: null,
  onIconClick: () => {},
  fallbackValue: () => {},
  disabledToggle: false,
};

export default CellValue;
