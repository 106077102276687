import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS, APPS_TYPE_LIST, VALID_ADMIN_ROLES } from '../const.config';

// Apps module configurations
export const LISTING_COLUMNS = [
  {
    id: 'name',
    label: 'Name',
    width: '20%',
    align: 'left',
    dataKey: 'name',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'installs',
    label: 'Installs',
    width: '10%',
    align: 'center',
    dataKey: 'installs',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'brdUsers',
    label: 'BRD Users',
    width: '10%',
    align: 'center',
    dataKey: 'brdUsers',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'type',
    label: 'Type',
    width: '10%',
    align: 'center',
    dataKey: 'type',
    sortable: true,
    type: CELL_DATA_TYPES.ENUM,
    enumValues: APPS_TYPE_LIST,
  },
  {
    id: 'apiKey',
    label: 'API Key',
    width: '15%',
    align: 'center',
    dataKey: 'apiKey',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'apiKeyCopy',
    label: '',
    width: '%2',
    align: 'left',
    dataKey: 'api_key',
    icon: 'fluent:copy-24-regular',
    action: ACTIONS.COPY.value,
    sortable: false,
    type: CELL_DATA_TYPES.ICON,
  },
  {
    id: 'lastInstallAt',
    label: 'Last Install App',
    width: '18%',
    align: 'center',
    dataKey: 'lastInstallAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMEAGO,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    width: '18%',
    align: 'center',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
    conditional: (val) => val.role !== VALID_ADMIN_ROLES.SUPER_ADMIN,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.CONFIG.value,
    label: ACTIONS.CONFIG.label,
  },
  {
    action: ACTIONS.MANAGE_CATEGORY.value,
    label: ACTIONS.MANAGE_CATEGORY.label,
    conditional: (val) => val.type === APPS_TYPE_LIST[1].id,
  },
];

export const VIEW_COLUMNS = [
  {
    id: 'name',
    label: 'Name',
    dataKey: 'name',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'description',
    label: 'Description',
    dataKey: 'description',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'type',
    label: 'Type',
    dataKey: 'type',
    enumValues: APPS_TYPE_LIST,
    type: CELL_DATA_TYPES.ENUM,
  },
  {
    id: 'apiKey',
    label: 'API Key',
    dataKey: 'apiKey',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'installs',
    label: 'Installs',
    dataKey: 'installs',
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'brdUsers',
    label: 'BRD Users',
    dataKey: 'brdUsers',
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'lastInstallAt',
    label: 'Last App Install',
    dataKey: 'lastInstallAt',
    type: CELL_DATA_TYPES.TIMEAGO,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];
