import React, { useState, forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
} from '@mui/material';
import Iconify from '../common/iconify.component';
import SnackbarInfo from '../common/snackbar-info.component';
import ImageUpload from './image-upload.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const UploadImage = ({
  title,
  dataId,
  onCancel,
  onSuccess,
  imageUrl,
  appId,
  category,
}) => {
  const [loading, setLoading] = useState(true);
  const [videoData, setVideoData] = useState({
    thumbnail: '',
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const showUpdatedImage = () => {};

  const setImageUrlCallBack = (v) => {
    setVideoData({
      thumbnail: v.imageUrl,
    });
  };

  const showToastMsg = (msgType, message) => {
    if (msgType === 'error') {
      setSnackbarInfo({
        show: true,
        type: 'error',
        message,
      });
    }

    if (msgType === 'success') {
      setSnackbarInfo({
        show: true,
        type: 'success',
        message,
      });
    }
  };

  useEffect(() => {
    setVideoData({
      ...videoData,
      thumbnail: imageUrl || '',
    });
    setLoading(false);
  }, []);
  return (
    <Dialog
      open
      onClose={() => onCancel()}
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="view-dialog-title">{`${title}`}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        <form id="upload-assets-form">
          <Box
            component="section"
            sx={{
              p: 3,
              border: '1px solid lightgrey',
              borderRadius: '5px',
              width: '100%',
            }}
          >
            <ImageUpload
              title="Image"
              dataId={dataId}
              showToastMsg={showToastMsg}
              setSnackbarInfo={setSnackbarInfo}
              videoData={videoData}
              loading={loading}
              showUpdatedImage={showUpdatedImage}
              setImageUrlCallBack={setImageUrlCallBack}
              onSuccess={onSuccess}
              appId={appId}
              category={category}
            />
          </Box>
        </form>
      </DialogContent>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

UploadImage.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  imageUrl: PropTypes.string.isRequired,
  appId: PropTypes.number,
  category: PropTypes.bool,
};

UploadImage.defaultProps = {
  appId: null,
  onCancel: () => {},
  onSuccess: () => {},
  category: false,
};

export default UploadImage;
