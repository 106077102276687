import React, { useState, useEffect } from 'react';
import { Card, Stack, Button, Drawer } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ListData from '../table-elements/list-data.component';
import Iconify from '../common/iconify.component';
import {
  LISTING_ACTIONS,
  LISTING_COLUMNS,
} from '../../config/module-configs/apps.config';
import FilterContent from './filter-content.component';
import {
  ACTIONS,
  LS_ADMIN_USER_ROLE,
  VALID_ADMIN_ROLES,
} from '../../config/const.config';
import SnackbarInfo from '../common/snackbar-info.component';
import AddEditApp from './add-edit-app.component';
import ViewApp from './view-app.component';
import { getAppsList } from '../../services/apps.service';
import ConfigBtnPopup from '../common/config/config-btn-pop-up';
import ROUTE_PATH from '../../config/routes.config';
import editUrl from '../../utils/url-edit.util';
import { getLocalValue } from '../../utils/local-cache.util';

const ListApps = () => {
  const accessRole = getLocalValue(LS_ADMIN_USER_ROLE);
  const columns = LISTING_COLUMNS;
  const actions =
    accessRole === VALID_ADMIN_ROLES.SUPER_ADMIN
      ? LISTING_ACTIONS
      : LISTING_ACTIONS.filter((item) => item.action === ACTIONS.VIEW.value);
  const navigate = useNavigate();

  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    error: false,
    loading: true,
    appliedFilters: {
      search: '',
      filterApp: null,
      filterPlatform: [],
      filterAppType: null,
    },
    filtersCounter: 0,
  });
  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [openFilters, setOpenFilters] = useState(false);

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleActionSelect = (selectedAction, selectedData) => {
    setDoAction({
      ...doAction,
      data: selectedData || null,
      action: selectedAction || null,
    });
  };
  const handleActionCancel = () => {
    setDoAction({
      ...doAction,
      data: null,
      action: null,
    });
  };
  const handleActionSuccess = (message = '') => {
    if (message) {
      setSnackbarInfo({
        ...snackbarInfo,
        show: true,
        type: 'success',
        message,
      });
    }

    handleActionCancel();
    handleRefreshData();
  };

  const handleToggleFilters = () => {
    setOpenFilters(!openFilters);
  };
  const handleApplyFilters = (selectedFilters) => {
    let counter = 0;
    if (selectedFilters.search.trim()) counter += 1;
    if (selectedFilters.filterApp) counter += 1;
    if (selectedFilters.filterPlatform) counter += 1;
    if (selectedFilters.filterAppType) counter += 1;

    setOptions({
      ...options,
      appliedFilters: {
        ...selectedFilters,
      },
      filtersCounter: counter,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
    handleToggleFilters();
  };
  const handleClearFilters = () => {
    handleToggleFilters();
    setOptions({
      ...options,
      appliedFilters: {
        search: '',
        filterApp: null,
        filterPlatform: [],
        filterAppType: null,
      },
      filtersCounter: 0,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const copyToClipBoard = (copyMe) => {
    try {
      navigator.clipboard.writeText(copyMe);
    } catch (err) {
      /* empty */
    }
  };
  useEffect(() => {
    const params = [];
    params.push(`page=${options.page + 1}`);
    params.push(`perPage=${options.rowsPerPage}`);
    if (options.sortBy && options.sortOrder) {
      params.push(`sortBy=${options.sortBy}`);
      params.push(`sortOrder=${options.sortOrder}`);
    }
    const filters = options.appliedFilters;
    if (filters.search) {
      params.push(`q=${encodeURIComponent(filters.search)}`);
    }
    if (filters.filterApp && filters.filterApp.id) {
      params.push(`appId=${encodeURIComponent(filters.filterApp.id)}`);
    }
    if (filters.filterPlatform.length > 0) {
      const platforms = [];
      filters.filterPlatform.forEach((p) => platforms.push(p.id));
      params.push(`platforms=${platforms.join(',')}`);
    }
    if (filters.filterAppType && filters.filterAppType.id) {
      params.push(`appType=${encodeURIComponent(filters.filterAppType.id)}`);
    }

    const paramsQuery = params.length > 0 ? `?${params.join('&')}` : '';
    getAppsList(paramsQuery)
      .then((res) => {
        setOptions({
          ...options,
          loading: false,
          totalRows: res?.data?.totalRows || 0,
          rows: res?.data?.rows || [],
          error: false,
        });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        });
      });
  }, [options.reloadCounter]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 1, py: 2 }}
      >
        <div>
          {accessRole === VALID_ADMIN_ROLES.SUPER_ADMIN && (
            <Button
              color="primary"
              variant="contained"
              startIcon={<Iconify icon="ic:round-add" />}
              onClick={() => handleActionSelect(ACTIONS.CREATE.value, null)}
            >
              Create New
            </Button>
          )}
        </div>
        <div>
          <Button
            color={options.filtersCounter > 0 ? 'warning' : 'inherit'}
            variant="contained"
            startIcon={<Iconify icon="ic:round-filter-list" />}
            sx={{ marginRight: 2 }}
            onClick={handleToggleFilters}
          >
            Filters
            {options.filtersCounter > 0 ? ` (${options.filtersCounter})` : ''}
          </Button>
          <Button
            color="inherit"
            variant="contained"
            startIcon={<Iconify icon="ic:twotone-refresh" />}
            onClick={handleRefreshData}
            disabled={options.loading}
          >
            Refresh
          </Button>
        </div>
      </Stack>

      <Card>
        <ListData
          columns={columns}
          rows={options.rows}
          page={options.page}
          rowsPerPage={options.rowsPerPage}
          totalRows={options.totalRows}
          loading={options.loading}
          actions={actions}
          error={options.error}
          sortBy={options.sortBy}
          sortOrder={options.sortOrder}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          onSortChange={handleSortingChange}
          onAction={handleActionSelect}
        />
      </Card>

      <Drawer
        anchor="right"
        open={openFilters}
        onClose={handleToggleFilters}
        PaperProps={{
          sx: { width: 300, border: 'none', overflow: 'hidden' },
        }}
      >
        <FilterContent
          values={options.appliedFilters}
          onCancel={handleToggleFilters}
          onSuccess={handleApplyFilters}
          onClear={handleClearFilters}
          hideSerachFilter
        />
      </Drawer>

      {doAction.action === ACTIONS.CREATE.value && (
        <AddEditApp
          title="Create New App"
          dataId={0}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}
      {doAction.action === ACTIONS.COPY.value &&
        copyToClipBoard(doAction?.data?.apiKey)}

      {doAction.action === ACTIONS.EDIT.value && (
        <AddEditApp
          title={`Edit App Details - ${doAction.data.name}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {doAction.action === ACTIONS.VIEW.value && (
        <ViewApp
          title={`App Details - ${doAction.data.name}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
        />
      )}

      {doAction.action === ACTIONS.CONFIG.value && (
        <ConfigBtnPopup
          title={`App Details - ${doAction.data.name}`}
          dataId={doAction.data.id}
          type={doAction.data.type}
          onCancel={handleActionCancel}
        />
      )}

      {doAction.action === ACTIONS.MANAGE_CATEGORY.value &&
        navigate(editUrl(ROUTE_PATH.APPS_MANAGE_CATEGORY, doAction.data.id), {
          state: { name: doAction.data?.name || '' },
        })}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

export default ListApps;
