import React from 'react';
import PropTypes from 'prop-types';
import { useRoutes, Navigate } from 'react-router-dom';
import { VALID_ADMIN_ROLES } from '../config/const.config';
import ROUTE_PATH from '../config/routes.config';
import LoginView from '../views/auth/login.view';
import DashboardView from '../views/dashboard/dashboard.view';
import AdminUsersView from '../views/admin-users/admin-users.view';
import PageNotFoundView from '../views/errors/not-found.view';
import LogoutView from '../views/auth/logout.view';
import ContentAppsView from '../views/content/apps.view';
import ReportsSessionsView from '../views/reports/app-sessions.view';
import UsersView from '../views/users/users.view';
import CategoriesView from '../views/content/categories.view';

const AppRouter = ({ isLoggedIn, accessRole }) => {
  const routeMappings = [];

  if (isLoggedIn) {
    routeMappings.push({
      path: ROUTE_PATH.LOGIN,
      element: <Navigate to={ROUTE_PATH.DASHBOARD} />,
      exact: true,
    });

    routeMappings.push({
      path: ROUTE_PATH.DASHBOARD,
      element: <DashboardView />,
      exact: true,
    });

    routeMappings.push({
      path: ROUTE_PATH.REPORTS_APP_SESSIONS,
      element: <ReportsSessionsView />,
      exact: true,
    });

    routeMappings.push({
      path: ROUTE_PATH.CONTENT_APPS,
      element: <ContentAppsView />,
      exact: true,
    });

    routeMappings.push({
      path: ROUTE_PATH.APPS_MANAGE_CATEGORY,
      element: <CategoriesView />,
      exact: true,
    });

    routeMappings.push({
      path: ROUTE_PATH.USERS,
      element: <UsersView />,
      exact: true,
    });

    routeMappings.push({
      path: ROUTE_PATH.LOGOUT,
      element: <LogoutView />,
      exact: true,
    });

    if (accessRole === VALID_ADMIN_ROLES.SUPER_ADMIN) {
      routeMappings.push({
        path: ROUTE_PATH.ADMIN_USERS,
        element: <AdminUsersView />,
        exact: true,
      });
    }
  }

  routeMappings.push({
    path: '*',
    element: isLoggedIn ? <PageNotFoundView /> : <LoginView />,
  });

  const routes = useRoutes(routeMappings);

  return routes;
};

AppRouter.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  accessRole: PropTypes.string.isRequired,
};

export default AppRouter;
