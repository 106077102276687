import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListApps from '../../components/content/list-apps.component';

const ContentAppsView = () => {
  const pageTitle = 'Apps';

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListApps />
    </AppLayout>
  );
};

export default ContentAppsView;
