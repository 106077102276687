import CELL_DATA_TYPES from '../cell-types.config';

// Images module configurations
const LISTING_COLUMNS = [
  {
    id: 'imageUrl',
    label: 'Image Preview',
    width: '5%',
    align: 'center',
    dataKey: 'imageUrl',
    sortable: false,
    type: CELL_DATA_TYPES.IMAGE,
  },
  {
    id: 'name',
    label: 'Name',
    width: '20%',
    align: 'left',
    dataKey: 'name',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'status',
    label: 'Status',
    width: '10%',
    align: 'left',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.SWITCH,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    width: '15%',
    align: 'center',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export default LISTING_COLUMNS;
