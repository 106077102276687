import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListUsers from '../../components/users/list-users.component';

const UsersView = () => {
  const pageTitle = 'User Management / Users';

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListUsers />
    </AppLayout>
  );
};

export default UsersView;
