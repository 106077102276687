import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Slide,
  Box,
} from '@mui/material';
import Iconify from '../common/iconify.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ImagePreview = ({ title, onCancel, imageUrl }) => (
  <Dialog
    open
    onClose={() => onCancel()}
    aria-labelledby="confirm-dialog-title"
    aria-describedby="confirm-dialog-description"
    TransitionComponent={Transition}
    maxWidth="md"
  >
    <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
    <IconButton
      aria-label="close"
      onClick={onCancel}
      size="small"
      sx={{
        position: 'absolute',
        right: 10,
        top: 15,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <Iconify icon="ic:outline-close" />
    </IconButton>

    <DialogContent dividers id="confirm-dialog-description">
      <Box
        component="img"
        sx={{
          minWidth: '300px',
        }}
        alt={title}
        src={imageUrl}
      />
    </DialogContent>
  </Dialog>
);

ImagePreview.propTypes = {
  title: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  imageUrl: PropTypes.string.isRequired,
};

export default ImagePreview;
