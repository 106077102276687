import {
  Button,
  Box,
  Card,
  CardMedia,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  FormControlLabel,
  Checkbox,
  TablePagination,
  IconButton,
  Slide,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  assignImagesByCategoryId,
  getImagesList,
  viewImagesByCategoryId,
} from '../../services/apps.service';
import StyledDialogActions from '../../theme/dialogActionStyles';
import Loading from '../table-elements/loading.component';
import SearchList from '../table-elements/search-list.component';
import NoData from '../table-elements/no-data.component';
import Iconify from '../common/iconify.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ManageCategoryImages = ({ dataId, title, onSuccess, onCancel }) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    reloadCounter: 0,
    q: null,
  });
  const searchImagesRef = useRef(null);

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
    });
    setDataLoaded(false);
  };

  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
    });
    setDataLoaded(false);
  };

  const handleSearchImages = () => {
    if (!dataLoaded) return;
    setOptions({
      ...options,
      q: searchImagesRef.current.value,
      reloadCounter: options.reloadCounter + 1,
      page: 0,
    });
    setDataLoaded(false);
  };

  const handleClearSearch = () => {
    if (!dataLoaded) return;
    setOptions({
      ...options,
      searchValue: '',
      q: null,
      reloadCounter: options.reloadCounter + 1,
    });
    setDataLoaded(false);
    searchImagesRef.current.value = '';
  };

  const handleSearchChange = (e) => {
    setOptions({
      ...options,
      searchValue: e.target.value || '',
    });
  };

  const handleUpdateImage = () => {
    const payload = {
      imageIds: selectedImages,
    };
    assignImagesByCategoryId(dataId, payload)
      .then(() => {
        onSuccess('Images assigned successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
      });
  };

  const handleImageClick = (data) => {
    setSelectedImages((prev) =>
      prev.includes(data.id)
        ? prev.filter((d) => d !== data.id)
        : [...prev, data.id]
    );
  };

  const handleGetImagesList = () => {
    const params = [];
    params.push(`page=${options.page + 1}`);
    params.push(`perPage=${options.rowsPerPage}`);
    if (options.q) {
      params.push(`q=${options.q}`);
    }
    params.push(`inActive=false`);
    const paramsQuery = params.length > 0 ? `?${params.join('&')}` : '';
    getImagesList(paramsQuery)
      .then((res) => {
        if (res.data.totalRows === 0) {
          setImageList([]);
          setDataLoaded(true);
        }
        if (res.data.totalRows > 0) {
          setOptions({
            ...options,
            totalRows: res?.data?.totalRows || 0,
          });
          setImageList(res.data.rows);
          setDataLoaded(true);
        }
      })
      .catch((e) => {
        const message =
          e.response?.data?.message ||
          'Something went wrong, please try again.';
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setImageList([]);
        setDataLoaded(true);
      });
  };

  const handleViewImagesById = () => {
    viewImagesByCategoryId(dataId)
      .then((res) => {
        if (res.data) {
          setSelectedImages(res.data);
        }
      })
      .catch((e) => {
        const message =
          e.response?.data?.message ||
          'Something went wrong, please try again.';
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setImageList([]);
        setDataLoaded(true);
      });
  };

  useEffect(() => {
    handleGetImagesList();
  }, [options.reloadCounter]);

  useEffect(() => {
    handleViewImagesById();
  }, []);

  return (
    <Dialog open TransitionComponent={Transition} fullWidth maxWidth="lg">
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box style={{ marginRight: 10 }}>{title}</Box>
          <SearchList
            label="Images"
            handleSearch={handleSearchImages}
            inputRef={searchImagesRef}
            onChange={handleSearchChange}
            options={options}
            clearSearch={handleClearSearch}
            loading={!dataLoaded}
          />
        </Box>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="manage-category-images-dialog">
        <Box mt={2} mb={5}>
          {!dataLoaded && <Loading />}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {dataLoaded && imageList.length === 0 && (
                <Grid item xs={12} align="center">
                  <NoData />
                </Grid>
              )}
              {dataLoaded &&
                imageList.length !== 0 &&
                imageList.map(
                  (m, index) =>
                    m.imageUrl !== '' && (
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card
                          sx={{
                            backgroundColor: '#f1f2f3',
                            border: selectedImages.includes(m.id)
                              ? '1px solid blue'
                              : '',
                          }}
                        >
                          <Box
                            mt={2}
                            mb={2}
                            display="flex"
                            justifyContent="center"
                            flexDirection="column"
                          >
                            <CardMedia
                              component="img"
                              alt="m.name"
                              height={150}
                              image={m.imageUrl}
                              style={{ 'object-fit': 'contain' }}
                            />
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  checked={selectedImages.includes(m.id)}
                                  onChange={() => handleImageClick(m)}
                                />
                              }
                              sx={{ justifyContent: 'center', margin: 0 }}
                            />
                            <Box
                              display="flex"
                              justifyContent="center"
                              marginX={2}
                              sx={{
                                textAlign: 'center',
                                overflow: 'hidden',
                                display: '-webkit-box;',
                                textOverflow: 'ellipsis',
                                '-webkit-box-orient': 'vertical',
                                '-webkit-line-clamp': '2',
                                'word-break': 'break-word',
                              }}
                            >
                              {m.name}
                            </Box>
                          </Box>
                        </Card>
                      </Grid>
                    )
                )}
            </Grid>
          </Grid>
        </Box>
        {dataLoaded && options.totalRows > 0 && (
          <TablePagination
            component="div"
            count={options.totalRows}
            page={options.page}
            onPageChange={(e, newPage) => handlePageChange(newPage)}
            rowsPerPage={options.rowsPerPage}
            onRowsPerPageChange={(e) => {
              handleRowsPerPageChange(Number(e.target.value));
            }}
          />
        )}
      </DialogContent>
      <StyledDialogActions>
        <Grid>
          <Button
            type="submit"
            form="add-edit-form"
            color="primary"
            variant="contained"
            disabled={!dataLoaded}
            onClick={handleUpdateImage}
            sx={{ marginRight: 2 }}
          >
            Save
          </Button>
          <Button color="secondary" variant="contained" onClick={onCancel}>
            Close
          </Button>
        </Grid>
      </StyledDialogActions>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

ManageCategoryImages.propTypes = {
  title: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  dataId: PropTypes.number.isRequired,
};

export default ManageCategoryImages;
