import React from 'react';
import { useLocation } from 'react-router-dom';
import AppLayout from '../../layout/app.layout';
import CategoriesTab from './categories-tab.view';

const CategoriesView = () => {
  const location = useLocation();

  const pageTitle = `Manage Categories › ${
    location?.state?.name ? location.state.name : ''
  }`;

  return (
    <AppLayout pageTitle={pageTitle}>
      <CategoriesTab />
    </AppLayout>
  );
};

export default CategoriesView;
