import CELL_DATA_TYPES from '../cell-types.config';

// App sessions module configurations
export const LISTING_COLUMNS = [
  {
    id: 'gameSessionId',
    label: 'Game Session ID',
    width: '10%',
    align: 'left',
    dataKey: 'game_session_id',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'category',
    label: 'Category',
    width: '15%',
    align: 'left',
    dataKey: 'category',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'difficulty',
    label: 'Difficulty',
    width: '10%',
    align: 'center',
    dataKey: 'difficulty',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'questions',
    label: 'Questions',
    width: '10%',
    align: 'center',
    dataKey: 'questions',
    sortable: false,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'gameType',
    label: 'GameType',
    width: '10%',
    align: 'left',
    dataKey: 'gameType',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'highScore',
    label: 'High Score',
    width: '10%',
    align: 'left',
    dataKey: 'highScore',
    sortable: false,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'score',
    label: 'Score',
    width: '10%',
    align: 'left',
    dataKey: 'score',
    sortable: false,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'player1Score',
    label: 'Player1 Score',
    width: '10%',
    align: 'left',
    dataKey: 'player1Score',
    sortable: false,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'player2Score',
    label: 'Player2/Computer Score',
    width: '10%',
    align: 'left',
    dataKey: 'player2Score',
    sortable: false,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'drawCount',
    label: 'Draw Count',
    width: '10%',
    align: 'left',
    dataKey: 'drawCount',
    sortable: false,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'totalRound',
    label: 'Total Round',
    width: '10%',
    align: 'left',
    dataKey: 'totalRound',
    sortable: false,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'gameStartTime',
    label: 'Game Start Time',
    width: '15%',
    align: 'left',
    dataKey: 'gameStartTime',
    sortable: false,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'gameEndTime',
    label: 'Game End Time',
    width: '15%',
    align: 'left',
    dataKey: 'gameEndTime',
    sortable: false,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export default LISTING_COLUMNS;
