import React from 'react';
import AppLayout from '../../layout/app.layout';
import Counters from '../../components/common/reports/counters.component';

const DashboardView = () => {
  const pageTitle = 'Home';

  return (
    <AppLayout pageTitle={pageTitle}>
      <Counters />
    </AppLayout>
  );
};
export default DashboardView;
