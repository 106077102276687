/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import beautify from 'js-beautify';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
} from '@mui/material';
import JsFileDownloader from 'js-file-download';
import { VIEW_COLUMNS } from '../../../config/module-configs/app-sessions.config';
import Iconify from '../iconify.component';
import Loading from '../../table-elements/loading.component';
import SnackbarInfo from '../snackbar-info.component';
import ButtonConfigs from '../../table-elements/button-configs';
// eslint-disable-next-line no-unused-vars
import {
  updateConfigById,
  updateDefaultConfigById,
  viewAppById,
} from '../../../services/apps.service';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ConfigBtnPopup = ({ title, dataId, type, onCancel, isLive = false }) => {
  const columns = VIEW_COLUMNS;
  const renderColumns = [];

  columns.forEach((item) => {
    if (!(isLive && item.id === 'sessionEndedAt')) {
      renderColumns.push(item);
    }
  });

  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [configData, setConfigData] = useState(null);
  const [updateCall, setUpdateCall] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const viewData = (viewDataId = dataId, isDownload = false) => {
    viewAppById(viewDataId, isDownload)
      .then((res) => {
        if (isDownload) {
          // eslint-disable-next-line no-unused-vars
          const download = new JsFileDownloader(
            JSON.stringify(res),
            `${rowData?.name?.replace(/ /g, '_') || 'app'}_config.json`
          );
        } else {
          setConfigData(res.data.configvalue);
          setRowData(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    viewData();
  }, [dataId, updateCall]);

  const handleUpdateBtn = (isDefault = true, fileUp = null) => {
    setLoading(true);
    const fileUpload = isDefault ? null : fileUp;
    if (isDefault) {
      updateDefaultConfigById(dataId, fileUpload, type)
        // eslint-disable-next-line no-unused-vars
        .then((res) => {
          if (res) {
            setUpdateCall(!updateCall);
            setLoading(false);
            setSnackbarInfo({
              ...snackbarInfo,
              show: true,
              type: 'success',
              message: 'Default config updated successfully.',
            });
          }
        })
        .catch((err) => {
          const message =
            err.response?.data?.message ||
            'Something went wrong, please try again.';

          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'error',
            message,
          });
          setLoading(false);
        });
    } else {
      updateConfigById(dataId, fileUpload)
        .then(() => {
          setUpdateCall(!updateCall);
          setLoading(false);
          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'success',
            message: 'Config updated successfully.',
          });
        })
        .catch((err) => {
          const message =
            err.response?.data?.message ||
            'Something went wrong, please try again.';

          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'error',
            message,
          });
          setLoading(false);
        });
    }
  };

  const handleChangeFile = (payload) => {
    handleUpdateBtn(false, payload);
  };

  // const options = { indent_size: 2, space_in_empty_paren: true };

  const handleBeautify = (inputJson) => {
    let formattedJson;
    try {
      // Beautifying JSON input
      const beautified = beautify.js(inputJson, {
        indent_size: 2,
        space_in_empty_paren: true,
      });
      formattedJson = beautified;
    } catch (error) {
      formattedJson = 'Invalid JSON';
    }
    return formattedJson;
  };

  return (
    <Dialog
      open
      onClose={() => onCancel()}
      aria-labelledby="view-dialog-title"
      aria-describedby="view-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="view-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="view-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <>
            <ButtonConfigs
              columns={renderColumns}
              row={rowData}
              handleUpdateBtn={handleUpdateBtn}
              handleChangeFile={handleChangeFile}
              viewData={viewData}
              dataId={dataId}
              setSnackbarInfo={setSnackbarInfo}
              snackbarInfo={snackbarInfo}
            />

            <Box
              sx={{
                width: '100%',
                height: '200px',
                overflowY: 'scroll',
                border: '1px solid #ccc',
                padding: '10px',
              }}
            >
              {configData && (
                <div
                  className="bg-secondary p-3"
                  id="json-string"
                  style={{ whiteSpace: 'pre' }}
                >
                  {handleBeautify(configData)}
                </div>
              )}
            </Box>
          </>
        )}
      </DialogContent>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

ConfigBtnPopup.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  isLive: PropTypes.bool.isRequired,
};

export default ConfigBtnPopup;
