import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import {
  Box,
  List,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  ListSubheader,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  LS_ADMIN_USER_ROLE,
  VALID_ADMIN_ROLES,
} from '../../../config/const.config';
import ROUTE_PATH from '../../../config/routes.config';
import { getLocalValue } from '../../../utils/local-cache.util';
import { PRIMARY } from '../../../theme/theme-colors';
import Iconify from '../../common/iconify.component';

const StyledSubHeader = styled((props) => <ListSubheader {...props} />)(() => ({
  background: 'inherit',
  color: 'white',
  textDecoration: 'underline',
}));

const StyledNavItem = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: 'white',
  borderRadius: theme.shape.borderRadius,
}));

const StyledNavItemIcon = styled(ListItemIcon)({
  width: 24,
  height: 24,
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 24,
});

const NavItem = ({ title, path, icon }) => (
  <StyledNavItem
    component={RouterLink}
    to={path}
    sx={{
      '&.active': {
        color: 'white',
        bgcolor: PRIMARY.main,
        fontWeight: 'fontWeightBold',
      },
    }}
  >
    <StyledNavItemIcon>{icon}</StyledNavItemIcon>
    <ListItemText disableTypography primary={title} />
  </StyledNavItem>
);
NavItem.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
};

const NavItems = () => {
  const accessRole = getLocalValue(LS_ADMIN_USER_ROLE);

  return (
    <Box>
      <List disablePadding sx={{ p: 1 }}>
        <NavItem
          title="Home"
          path={ROUTE_PATH.DASHBOARD}
          icon={<Iconify icon="ic:round-dashboard" />}
        />

        <NavItem
          title="Apps"
          path={ROUTE_PATH.CONTENT_APPS}
          icon={<Iconify icon="ic:twotone-checklist" />}
        />

        <StyledSubHeader component="div">User Management</StyledSubHeader>
        <NavItem
          title="Users"
          path={ROUTE_PATH.USERS}
          icon={<Iconify icon="ci:users" />}
        />

        <StyledSubHeader component="div">Reports</StyledSubHeader>
        <NavItem
          title="App Sessions"
          path={ROUTE_PATH.REPORTS_APP_SESSIONS}
          icon={<Iconify icon="ion:analytics-sharp" />}
        />

        {accessRole === VALID_ADMIN_ROLES.SUPER_ADMIN && (
          <>
            <StyledSubHeader component="div">Settings</StyledSubHeader>
            <NavItem
              title="Admin Access"
              path={ROUTE_PATH.ADMIN_USERS}
              icon={<Iconify icon="mdi:users-check" />}
            />
          </>
        )}
      </List>
    </Box>
  );
};

export default NavItems;
