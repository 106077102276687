import axios from 'axios';
import prepareRequestOptions from '../utils/admin-apis.util';

export const getAppsList = async (params = '') => {
  const options = prepareRequestOptions(
    `/admin/apps/list${params}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const createNewApp = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/app/create',
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const viewAppById = async (id, isDownload = false) => {
  const options = prepareRequestOptions(
    `/admin/app/${id}/view${isDownload ? '?download=true' : ''}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const updateAppById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/app/${id}/update`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const updateConfigById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/app/${id}/config`,
    'POST',
    payload,
    true,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const updateDefaultConfigById = async (id, payload, type) => {
  const options = prepareRequestOptions(
    `/admin/app/${id}/default-config?type=${type}`,
    'POST',
    payload,
    true,
    false
  );

  const response = await axios(options);
  return response.data;
};

export const getCategoriesList = async (params = '') => {
  const options = prepareRequestOptions(
    `/admin/content/categories/list${params}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const createNewCategory = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/content/category/create',
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const viewCategoryById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/content/category/${id}/view`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const updateCategoryById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/content/category/${id}/update`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const toggleStatus = async (payload, type) => {
  const options = prepareRequestOptions(
    `/admin/content/${type}/status-update`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const toggleDefaultCategory = async (payload) => {
  const options = prepareRequestOptions(
    `/admin/content/default-category/update`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getImagesList = async (params = '') => {
  const options = prepareRequestOptions(
    `/admin/content/images/list${params}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const assignImagesByCategoryId = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/content/category/${id}/assign-images`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const viewImagesByCategoryId = async (id) => {
  const options = prepareRequestOptions(
    `/admin/content/category/${id}/view-images`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const uploadImage = async (payload) => {
  const options = prepareRequestOptions(
    `/admin/upload/image`,
    'POST',
    payload,
    true,
    true
  );

  const response = await axios(options);
  return response.data;
};
